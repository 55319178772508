import React from 'react';
import AppLayout from './components/AppLayout';

function App() {
  const logo = 'OpsViewLogo5.png'; // Replace with your logo path
  const customerName = 'Minuteman Security and Life Safety';
  const userName = 'User Name';
  const menuItems = ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'];
  const cameras = [
    { name: 'Camera 1', position: [51.505, -0.09], lastImage: 'path/to/image1.jpg' },
    { name: 'Camera 2', position: [51.515, -0.10], lastImage: 'path/to/image2.jpg' },
    // Add more cameras as needed
  ];

  return (
    <AppLayout
      logo={logo}
      customerName={customerName}
      userName={userName}
      menuItems={menuItems}
      cameras={cameras}
    />
  );
}

export default App;
