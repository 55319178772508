import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

const Sidebar = ({ menuItems }) => (
  <List component="nav">
    {menuItems.map((item, index) => (
      <ListItem button key={index}>
        <ListItemText primary={item} />
      </ListItem>
    ))}
  </List>
);

export default Sidebar;
