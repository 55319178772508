import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@material-ui/core';

const Header = ({ logo, customerName, userName }) => (
  <AppBar position="static">
    <Toolbar>
      <Box display="flex" flexGrow={1}>
        <img src={logo} alt="logo" style={{ height: 40 }} />
      </Box>
      <Typography variant="h6">
        {customerName} - {userName}
      </Typography>
    </Toolbar>
  </AppBar>
);

export default Header;
