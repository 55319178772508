import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = ({ cameras }) => (
  <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: "100vh" }}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
    {cameras.map((camera, index) => (
      <Marker key={index} position={camera.position}>
        <Popup>
          {camera.name} <br /> <img src={camera.lastImage} alt="Last capture" style={{ width: '100%' }} />
        </Popup>
      </Marker>
    ))}
  </MapContainer>
);

export default MapComponent;
