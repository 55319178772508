import React from 'react';
import { Grid } from '@material-ui/core';
import Header from './Header';
import Sidebar from './Sidebar';
import CameraList from './CameraList';
import MapComponent from './MapComponent';

const AppLayout = ({ logo, customerName, userName, menuItems, cameras }) => (
  <Grid container>
    <Grid item xs={12}>
      <Header logo={logo} customerName={customerName} userName={userName} />
    </Grid>
    <Grid item xs={2}>
      <Sidebar menuItems={menuItems} />
    </Grid>
    <Grid item xs={3}>
      <CameraList cameras={cameras} />
    </Grid>
    <Grid item xs={7}>
      <MapComponent cameras={cameras} />
    </Grid>
  </Grid>
);

export default AppLayout;
