import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

const CameraList = ({ cameras }) => (
  <List component="nav">
    {cameras.map((camera, index) => (
      <ListItem button key={index}>
        <ListItemText primary={camera.name} />
      </ListItem>
    ))}
  </List>
);

export default CameraList;
